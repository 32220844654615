import React, { useEffect, useState } from 'react';
import '../user/User.scss';
// import avatar from '../../../images/bruce.jpg';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import TabWithoutUrl from '../../../components/TabWithoutUrl';
import UserProfile from '../components/UserProfile';
import AlarmSubscription from '../components/AlarmSubscription';
import NotificationPreferences from '../components/notification-Preferences/NotificationPreferences';
import MeasurementDisplay from '../components/MeasurementDisplay';
import AdminSecurity from '../components/security/AdminSecurity';
import { fetchUserById, fetchUsers, updateUserStatus } from '../UserSlice';
import { ToastContainer, toast } from 'react-toastify';
import ConfirmationModel from '../components/confirmation-model/ConfirmationModel';
import thinArrow from '../../../images/thinArrow.svg';
import { capitalizeFirstLetter, datetimeFormat } from '../../common/DateRangeSelector/DefaultRanges';
import Loader from '../../common/page-loader/ComponentLoader';

const SelectedUser: React.FC = () => {
  const [isUserActionDropdown, setIsUserActionDropdown] = useState<boolean>(false);
  const [action, setAction] = useState<string>('');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const hElement = document.getElementById('main-root');

  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.user.currentUser);
  const loading = useAppSelector((state) => state?.user?.userLoading);

  //const avatar='';
  const tabs = [
    { label: 'Profile', component: UserProfile },
    { label: 'Security', component: AdminSecurity },
    { label: 'Alarm subscription', component: AlarmSubscription },
    { label: 'Notification preferences', component: NotificationPreferences },
    { label: 'Measurement display', component: MeasurementDisplay },
  ];

  const openUserActionDropdown = () => {
    setIsUserActionDropdown(!isUserActionDropdown);
  };

  const handleUser = async (status: string) => {
    if (currentUser?.id) {
      setAction(status);
      setIsConfirmationModalOpen(true);
      hElement && hElement?.classList.add('modal-open');
    }
  };

  const handleConfirmationClose = () => {
    setIsConfirmationModalOpen(false);
    hElement && hElement?.classList.remove('modal-open');
  };

  const handleConfirmationOk = async () => {
    setIsConfirmationModalOpen(false);
    const message =
      action === 'Delete'
        ? 'Deleted successfully'
        : action === 'InActive'
        ? 'Inactive successfully'
        : 'Active successfully';

    
    if (currentUser) {
      const response = await dispatch(updateUserStatus({ ids: [currentUser?.id], status: action }));
      if (response?.payload?.isStatusChanged) {
        toast.success(`${message}. ${response?.payload?.count} user(s) affected.`);
        if (action === 'Delete') {
          dispatch(fetchUsers());
        } else {
          dispatch(fetchUserById(currentUser?.id));
          dispatch(fetchUsers());
        }
      } else {
        toast.error('Failed to update user.');
      }
    }
  };

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (event.target instanceof HTMLElement && event.target.closest('.select-name')) return;
      setIsUserActionDropdown(false);
    };
    document.body.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.body.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  return (
    <>
      <div className='card username-card m-0'>
        <div className='body'>
          {loading ? (
            <div className='flex items-center w-100 my-7'>
              <Loader />
            </div>
          ) : currentUser ? (
            <>
              <div className='useravatar'>
                {currentUser?.profilePic ? (
                  <div className='avatar-img'>
                    <img src={currentUser?.profilePic} alt='Avatar' />
                  </div>
                ) : (
                  <div className={`p-5 profile-image-container ${currentUser?.status === 'InActive' ? 'inactive-image-container' : ''}`}>
                    <p className='profile-image-text'>
                      {currentUser?.firstName?.charAt(0).toUpperCase()}
                      {currentUser?.lastName?.charAt(0).toUpperCase()}
                    </p>
                  </div>
                )}
                <div className='useravatar-details'>
                  <div className='flex items-center justify-center'>
                    <span className='title'>{currentUser && `${capitalizeFirstLetter(currentUser?.firstName)} ${capitalizeFirstLetter(currentUser?.lastName)}`}</span>
                    <span className={`${currentUser && currentUser?.status === 'InActive' ? 'status' : ''}`}>
                      {currentUser && currentUser?.status === 'InActive' && capitalizeFirstLetter(currentUser?.status)}
                    </span>
                  </div>
                  <div className='job-role'>{currentUser && `${currentUser?.jobTitle} @${currentUser?.company}`}</div>
                  <div className='job-role pt-2'><span>{currentUser && `Last active: ${datetimeFormat(currentUser?.lastLoginDate)}`}</span></div>
                </div>
              </div>

              <div className='select-name'>
                <div
                  className={`custom-dropdown user-dropdown ${isUserActionDropdown ? 'open' : ''}`}
                  onClick={openUserActionDropdown}
                >
                  <div className={`selected-option ${isUserActionDropdown ? 'active-user-actions' : ''}`}>
                    User actions
                    <img
                      src={thinArrow}
                      alt='chevron-down'
                      className={`user-arrow-icon ${isUserActionDropdown ? 'rotate-180' : ''}`}
                    />
                  </div>
                  {isUserActionDropdown && (
                    <ul className='dropdown-list'>
                      {currentUser?.status === 'InActive' ? (
                        <li onClick={() => handleUser('Active')}>Activate user</li>
                      ) : (
                        <li onClick={() => handleUser('InActive')}>Inactive user</li>
                      )}
                      <li onClick={() => handleUser('Delete')}>Remove user</li>
                    </ul>
                  )}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className='user-ui-button-toolbar'>
        <TabWithoutUrl tabs={tabs} value={currentUser?.id} />
      </div>
      <div className='user-divider'></div>
      <ToastContainer position='top-right' autoClose={3000} />

      <ConfirmationModel
        isValidAction={isConfirmationModalOpen}
        isModalClose={handleConfirmationClose}
        isModalOk={handleConfirmationOk}
        action={action}
      />
    </>
  );
};

export default SelectedUser;
