import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    startOfYear,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    isSameDay,
    subYears,
    endOfYear
  } from 'date-fns';
   
 export const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    last7Days: startOfDay(addDays(new Date(), -6)),
    last30Days: startOfDay(addDays(new Date(), -29)),
    last90Days: startOfDay(addDays(new Date(), -89)),
    last180Days: startOfDay(addDays(new Date(), -179)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    startOfLastYear: startOfYear(subYears(new Date(), 1)),
    endOfLastYear: endOfYear(subYears(new Date(), 1)),
  };
   
  const staticRangeHandler = {
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
    isSelected(range: { startDate: number | Date; endDate: number | Date; }) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  };
   
  export function createStaticRanges(ranges: any[]) {
    return ranges.map(range => ({ ...staticRangeHandler, ...range }));
  }
   
  export const defaultStaticRanges = createStaticRanges([
    {
      label: 'Today',
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: 'Yesterday',
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
    },
   
    {
      label: 'Last 7 days',
      range: () => ({
        startDate: defineds.last7Days,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: 'Last 30 days',
      range: () => ({
        startDate: defineds.last30Days,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: 'Last 90 days',
      range: () => ({
        startDate: defineds.last90Days,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: 'Last 180 days',
      range: () => ({
        startDate: defineds.last180Days,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: 'This Year',
      range: () => ({
        startDate: defineds.startOfYear,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: 'Last Year',
      range: () => ({
        startDate: defineds.startOfLastYear,
        endDate: defineds.endOfLastYear,
      }),
    },
    {
        label: 'All Time',
        range: () => ({
          startDate: defineds.startOfLastMonth,
          endDate: defineds.endOfToday,
        }),
      },
  ]);

  export const customStaticRanges = createStaticRanges([
    {
      label: 'Today',
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: 'Yesterday',
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
    },
   
    {
      label: 'Last 7 days',
      range: () => ({
        startDate: defineds.last7Days,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: 'Last 30 days',
      range: () => ({
        startDate: defineds.last30Days,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: 'Last 90 days',
      range: () => ({
        startDate: defineds.last90Days,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: 'Last 180 days',
      range: () => ({
        startDate: defineds.last180Days,
        endDate: defineds.endOfToday,
      }),
    },
  ]);

  export const formateDate = (date: Date) => {
    const yyyy = date.getFullYear();
    let mm: string | number = date.getMonth() + 1;
    let dd: string | number = date.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return mm + '-' + dd + '-' + yyyy;
}

export const formatStringDate = (dateString: string | undefined) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export const capitalizeFirstLetter = (s: string | undefined) => {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

export const datetimeFormat = (date: string | undefined) => {
  if (!date) return '';
  const newDate = new Date(date);
  const month = String(newDate.getMonth() + 1).padStart(2, '0');
  const day = String(newDate.getDate()).padStart(2, '0');
  const year = newDate.getFullYear();
  const dateFormat =  `${month}/${day}/${year}`;
  let hours = newDate.getHours();
  let minutes: any = newDate.getMinutes();
  let seconds: any = newDate.getSeconds();

  const meridiem = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;
  const timeString = hours + ':' + minutes + ':' + seconds + ' ' + meridiem;
  return dateFormat + ' ' + timeString;
}