import { http } from "../../config/HttpCommon";
import { Role } from "../../models/Role";
import { AppUser } from "./model/AppUser";
import { Notification } from './model/Notification';

export const getUsers = async () => {
  const response = await http.get('/getUsers');
  return response.data;
};

export const getUserById = async (id: string) => {
  const response = await http.get(`/getUser/${id}`);
  return response.data;
};

export const getUserNotificationByUserId = async (id: string) => {
  const response = await http.get(`/GetUserNotification/${id}`);
  return response.data;
}

export const login = async (credentials: { username: string, password: string }) => {
  const response = await http.post('/loginUser', credentials);
  return response.data;
};

export const createUserData = async (user: AppUser) => {
  try {
    const response = await http.post('/createUser', user);
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

export const createUserNotificationData = async (notification: Notification) => {
  try {
    const response = await http.post('/CreateUserNotification', notification)
    return response.data;
  } catch (error) {
    console.error('Error creating schedule data :', error);
    throw error;
  }
}

export const updateUserData = async (id: string, user: AppUser) => {
  try {
    const response = await http.put(`/updateUser/${id}`, user);
    return response.data;
  } catch (error) {
    throw new Error('Failed to update user.');
  }
};

export const updateNotificationPreferencesData = async (id: string, notificationPreferences: Notification) => {
  const response = await http.put(`/UpdateUserNotification/${id}`, notificationPreferences);
  return response.data;
};

export const updateUserPermissionData = async (id: string, roles: Role[]) => {
  const response = await http.put(`/UpdateUserPermission/${id}`, roles);
  return response.data;
};

export const deleteScheduleData = async (id: string, deleteNotification: any) => {
  const response = await http.delete(`DeleteUserNotification?Id=${id}`, deleteNotification);
  return response.data;
}
// Function to reset password

export const notifyPasswordChange = async (id: string) => {
  const response = await http.get(`notifyResetPassword/${id}`);
  return response.data; // Assuming the response is a boolean value
};

export const changeUserStatus = async (userStatus: { ids: string[], status: string }) => {
  try {
    const response = await http.post(`/changeUserStatus`, userStatus);
    return response.data;
  } catch (error) {
    throw new Error('Failed to update user.');
  }
};
// Function to get operators
export const getOperators = async () => {
  const response = await http.get(`/GetOperators`);
  return response.data;
}