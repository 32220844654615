import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import './Security.scss';
import { fetchUserById, updateUserPermissions } from '../../UserSlice';
import { Role } from '../../../../models/Role';
import ToggleSwitch from '../../../common/ToggleSwitch/ToggleSwitch';
import { ToastContainer } from 'react-toastify';
import { AppUser } from '../../model/AppUser';
import Loader from '../../../common/page-loader/ComponentLoader';

const AdminSecurity: React.FC<{ value?: string }> = ({ value }) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state?.user?.loading);
  const currentUser = useAppSelector((state) => state.user.currentUser);
  const [currentRoles, setCurrentRoles] = useState<Role[]>([]);
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  useEffect(() => {
    const fetchUser = async () => {
      if (value) {
        await dispatch(fetchUserById(value));
      }
    };
    fetchUser();
  }, [dispatch, value]);

  useEffect(() => {
    if (currentUser?.roles) {
      setCurrentRoles(currentUser.roles);
    }
  }, [currentUser?.roles]);

  const isRoleActive = (role: Role) => currentRoles.includes(role);

  // const handleResetPassword = async () => {
  //   if (value) {
  //     const response = await dispatch(resetUserPassword(value)).unwrap();
  //     if (response) {
  //       toast.info('Password reset email sent.');
  //     } else {
  //       toast.error('Failed to send password reset email.');
  //     }
  //   }
  // };

  const handleRoleClick = async (roleNumber: Role) => {
    setCurrentRoles((prevRoles) => {
      const updatedRoles = prevRoles.includes(roleNumber)
        ? prevRoles.filter((role) => role !== roleNumber)
        : [...prevRoles, roleNumber];

      if (currentUser?.id) {
        dispatch(
          updateUserPermissions({
            id: currentUser.id,
            roles: updatedRoles,
          }),
        ).then(() => {
          if (currentUser.id === storedUser.id) {
            dispatch(fetchUserById(storedUser.id))
            localStorage.setItem('loggedInUser', JSON.stringify({ ...currentUser, roles: updatedRoles }))
          }
        });
      }

      return updatedRoles;
    });
  };

  const getRoleDescription = (role: number): string => {
    switch (role) {
      case Role.Admin:
        return 'Global administration, edit graph data, add new adhoc groups';
      case Role.Adminlite:
        return 'Group building';
      case Role.Welladmin:
        return 'Add / delete wells, change well names, change alias on group status columns, and add new custom pumping units';
      case Role.Wellcontrol:
        return 'Well control, modifying controller values, setpoints, and controller modes';
      case Role.Wellconfig:
        return 'Well configuration, edit / add meter and test data, edit / add other measurements data, enable / disable wells';
      case Role.WellconfigLite:
        return 'Add / edit well comments';
      default:
        return '';
    }
  };

  return (
    <>
      <div className='profile-info ui pt-6'>
        {/* <div className='p-1 w-100'>
          <div className='card' style={{ margin: '0', width: '100%', maxWidth: 'unset' }}>
            <button className='btn reset btn-primary' disabled>
              Reset Password
            </button>
          </div>
        </div> */}
        {loading ? (
          <div className='flex items-center loader-margin'>
            <Loader />
          </div>
        ) : currentUser ? (
          <div className='security-info'>
            <div className='p-1 w-100'>
              <div className='card' style={{ margin: '0', width: '100%' }}>
                <div className='toggle-wrapper'>
                  <ToastContainer position='bottom-right' autoClose={3000} />
                  {Object.keys(Role)
                    .filter((key) => isNaN(Number(key)))
                    .map((roleKey) => {
                      const role = Role[roleKey as keyof typeof Role] as number;
                      return (
                        <div key={roleKey} className='toggle-item'>
                          <ToggleSwitch
                            checked={isRoleActive(role)}
                            onChange={() => handleRoleClick(role)}
                            label={roleKey}
                            value={role.toString()}
                          />
                          <p>{getRoleDescription(role)}</p>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        ) : ''}
      </div>
    </>
  );
};

export default AdminSecurity;
