import React, { useState, useEffect } from 'react';
import WellControlOptions from './WellControlOptions';
import './asset-details-header.scss';
import { Options } from '../../data/Options';
import { wellOptions } from '../../data/WellOptions';
import { useNavigate } from 'react-router-dom';
import {  updateAsset } from '../../../navigation/AssetGroupsSlice';
import { useAppDispatch } from '../../../../hooks/storeHooks';
import refreshIcon from '../../../../images/refresh-cw-02.svg';
import moment from 'moment-timezone';

interface AssetList {
  assetName: string | undefined;
  assetId: string | undefined;
  industryApplicationId: number | undefined;
}

const AssetDetailsHeader: React.FC<{
  groupName?: string;
  assetList: AssetList[];
  selectedOption: Options | undefined;
  setSelectedOption: (option: Options) => void;
}> = ({ groupName, selectedOption, setSelectedOption, assetList }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleOptionSelect = (option: Options) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
  };
  const [lastUpdatedDateTime, setLastUpdatedDateTime] = useState('');

  const refreshTime = () => {
    setLastUpdatedDateTime(moment().format('MM/DD/YYYY hh:mm:ss A'));
  };

  useEffect(() => {
    refreshTime();
    // setSelectedOption({
    //   wellId: '',
    //   wellName: ,
    // });
  }, []);

  const handleRefreshClick = () => {
    // window.location.reload()
    const href = window.location.href;
    window.location.href = href;

  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleWellOptionChange = (option: AssetList) => {
    setSelectedOption({
      wellId: option?.assetId ?? '',
      wellName: option?.assetName ?? '',
      assetGroupName: groupName ? groupName : '',
    });
    const selectedAsset: { assetId: string, assetName: string, assetGroupName: string } = { assetId: option?.assetId ?? '', assetName: option?.assetName ?? '', assetGroupName: groupName ? groupName : '' };
    if (option.assetName) dispatch(updateAsset(selectedAsset));
    sessionStorage.setItem('selectedWellName', JSON.stringify(selectedAsset));
  };

  useEffect(() => {
    handleOptionSelect(wellOptions[0]);
  }, []);

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (event.target instanceof HTMLElement && event.target.closest('.select-name')) return;
      setIsDropdownOpen(false);
    };
    document.body.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.body.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const handleAssetGroupNameClick = () => {
    if (groupName)
      sessionStorage.setItem("selectedGroupName", groupName)
    navigate('/layout/group-dashboard', { state: { groupNameSet: groupName } });
  }

  return (
    <div className='dashboard-first-block'>
      <div className='top-header grid grid-cols-2 gap-2'>
        <div>
          <h3 className='page-title' onClick={() => handleAssetGroupNameClick()}>
            {groupName === '-Clients' ? 'Well Groups' : groupName}
          </h3>
          <div className='select-name'>
            <div className={`custom-dropdown ${isDropdownOpen ? 'open' : ''}`} onClick={toggleDropdown}>
              <div className='selected-option'>
                {selectedOption?.wellName}
                <span>&#9660;</span>
              </div>
              {isDropdownOpen && (
                <ul className='dropdown-list max-height'>
                  {assetList.map((option, index) => (
                    <li key={index} onClick={() => handleWellOptionChange(option)}>
                      {option.assetName}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className='flex justify-end'>
          <div className='inner-wrapper'>
            <div>
              <WellControlOptions
                onSelectOption={handleOptionSelect}
                selectedOption={selectedOption || wellOptions[0]}
              />
            </div>
            <div className='pipe'>|</div>
            <div className='flex justify-end' onClick={handleRefreshClick}>
              <p className='text-timestamp'>Last updated on {lastUpdatedDateTime}</p>
              <img src={refreshIcon} alt='refresh-icon' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetDetailsHeader;
