import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeng/resources/themes/saga-blue/theme.css';
import 'primeng/resources/primeng.min.css';
import 'react-multi-date-picker/styles/layouts/mobile.css';
import '../../schduledSpeedChangeFrequency.scss';
import 'react-multi-date-picker/styles/layouts/mobile.css';
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css';
import DatePicker from 'react-multi-date-picker';
import { DateObject } from 'react-multi-date-picker';
import Toolbar from 'react-multi-date-picker/plugins/toolbar';
import '../../schduledSpeedChangeFrequency.scss';
import { convertFromISO, SimpleSpeedChange } from '../../model/SimpleSpeedChange';
import 'primeng/resources/themes/saga-blue/theme.css';
import 'primeng/resources/primeng.min.css';
import { createScheduleById } from '../../ScheduleSpeedChangeService';
import { useDispatch } from 'react-redux';
import { fetchScheduleById, updateScheduleSpeedChange } from '../../ScheduleSpeedChangeSlice';
import { AppDispatch } from '../../../../../../store';
import { useAppSelector } from '../../../../../../hooks/storeHooks';
import { SteppedSpeedChange } from '../../model/SteppedSpeedChange';
import Info_Circle from '../../../../../../../src/images/error-info-icon.svg';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Back_Left from '../../../../../../../src/images/arrow-narrow-left.svg';
import Question from '../../../../../../../src/images/question-mark.svg';
import Calendar from '../../../../../../../src/images/calendar.svg';
import { AppUser } from '../../../../../user/model/AppUser';

interface SimpleSpeedChangeProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (simpleSpeedChanges: SimpleSpeedChange) => void;
  simpleSpeedChange: SimpleSpeedChange | null;
  steppedSpeedChange: SteppedSpeedChange | null;
  editMode: boolean;
}

const ScheduledSimpleSpeedChange: React.FC<SimpleSpeedChangeProps> = ({
  isOpen,
  onClose,
  onSave,
  simpleSpeedChange,
  steppedSpeedChange,
  editMode,
}) => {

  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const [showHeader] = useState(true);
  const selectedGroupName = '';
  const [updatedUserId, setupdatedUserId] = useState<string>(simpleSpeedChange?.userId || '');
  const [updatedWellId, setupdatedWellId] = useState<string>(simpleSpeedChange?.wellId || '');
  const [updatedWellName, setupdatedWellName] = useState<string>(simpleSpeedChange?.wellName || '');
  const [updatedGroupName, setupdatedGroupName] = useState<string>(simpleSpeedChange?.groupName || '');
  const [updatedDeviceId, setupdatedDeviceId] = useState<string>(simpleSpeedChange?.deviceId || '');
  const [updatedNewFrequency, setupdatedNewFrequency] = useState<string>(simpleSpeedChange?.newFrequency || '');
  const [updatedScheduledDate, setupdatedScheduledDate] = useState<Date | null>(null);
  const ToastContainer = () => <div></div>;
  const [newFrequencyErrorMessage, setNewFrequencyErrorMessage] = useState('');
  const [timeInput, setTimeInput] = useState<string>('');
  const [ampmInput, setAmPmInput] = useState<string>('');

  useEffect(() => {
    if (simpleSpeedChange) {
      const convertedDateTime = convertFromISO(simpleSpeedChange?.scheduleDateTime);
      setTimeInput(convertedDateTime ? convertedDateTime?.time : '');
      setAmPmInput(convertedDateTime ? convertedDateTime?.ampm : '');
      setupdatedScheduledDate(convertedDateTime ? new Date(convertedDateTime?.date) : null)
      setupdatedScheduledDescription(simpleSpeedChange?.description);
    }
  }, [simpleSpeedChange]);

  const [updatedScheduledDescription, setupdatedScheduledDescription] = useState<string>('');
  const scheduleData = useAppSelector((state) => state?.scheduleSpeedChange?.scheduleSpeedChange);
  const wellName = useAppSelector((state) => state.scheduleSpeedChange.wellName.payload);
  const dispatch: AppDispatch = useDispatch();
  const selectedAsset = useAppSelector((state) => state.assetGroups) ?? '';

  useEffect(() => {
    if (simpleSpeedChange?.scheduleDateTime) {
      setupdatedUserId(simpleSpeedChange.userId || '');
      setupdatedWellId(simpleSpeedChange.wellId || '');
      setupdatedWellName(simpleSpeedChange.wellName || '');
      setupdatedGroupName(simpleSpeedChange.groupName || '');
      setupdatedDeviceId(simpleSpeedChange.deviceId || '');
      setupdatedNewFrequency(simpleSpeedChange.newFrequency || '');
      // setupdatedScheduledDate(new Date(simpleSpeedChange.scheduleDate));
      // setupdatedScheduledTime(simpleSpeedChange?.scheduleTime ? new Date(simpleSpeedChange.scheduleTime[0]) : null);
      setupdatedScheduledDescription(simpleSpeedChange?.description || '');
    }
  }, [simpleSpeedChange, updatedDeviceId, updatedWellId, updatedGroupName, updatedWellName, updatedUserId]);

  const handleCreateOrUpdate = async () => {
    try {
      const isNewFrequencyValid = /^(?:\d{1,3}(?:\.\d*)?|\.\d+)$/.test(updatedNewFrequency);

      if (!isNewFrequencyValid) {
        toast.error('Frequency must be a positive number', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      const currentDate = new Date();
      const formattedDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;

      const selectedScheduleTime = new Date(
        `${updatedScheduledDate ? updatedScheduledDate.toLocaleDateString() : formattedDate} ${timeInput} ${ampmInput}`,
      );
      if (selectedScheduleTime < currentDate) {
        toast.error('Schedule time cannot be in the past', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      const updatedSimpleSpeedChange = {
        userId: storedUser?.id,
        wellId: selectedAsset?.selectedAssetId ?? '',
        wellName: selectedGroupName,
        groupName: selectedGroupName,
        deviceId: storedUser?.id,
        currentFrequency: '57.4',
        newFrequency: updatedNewFrequency,
        scheduleDateTime: selectedScheduleTime?.toISOString(),
        description: updatedScheduledDescription,
        id: '',
        scheduleId: 0,
      };

      const updatedSteppedSpeedChange = editMode ? [] : steppedSpeedChange ? [steppedSpeedChange] : [];

      let response;
      if (editMode && simpleSpeedChange) {
        response = await dispatch(
          updateScheduleSpeedChange({
            id: scheduleData?.id || '',
            speedChanges: {
              simpleSpeedChanges: [updatedSimpleSpeedChange],
              steppedSpeedChanges: updatedSteppedSpeedChange,
            },
          }),
        );
      } else {
        response = await createScheduleById({
          userId: updatedSimpleSpeedChange.userId,
          wellId: updatedSimpleSpeedChange.wellId,
          wellName: updatedSimpleSpeedChange.wellName,
          groupName: updatedSimpleSpeedChange.groupName,
          deviceId: updatedSimpleSpeedChange.deviceId,
          simpleSpeedChanges: [updatedSimpleSpeedChange],
          steppedSpeedChanges: updatedSteppedSpeedChange,
        });
      }

      if (response) {
        toast.success('Scheduled speed change applied', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        if (!(editMode && simpleSpeedChange)) {
          const firstSimpleSpeedChange = response.simpleSpeedChanges?.[0];
          if (firstSimpleSpeedChange) {
            onSave(firstSimpleSpeedChange);
          }
        }

        dispatch(
          fetchScheduleById({
            userId: storedUser?.id,
            deviceId: storedUser?.id,
            wellId: selectedAsset?.selectedAssetId ?? '',
            wellName: selectedGroupName,
          }),
        );

        onClose();
      }
    } catch (error) {
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error('Error creating or updating schedule:', error);
    }
  };

  const handleInputChange = (fieldName: string, value: string | Date | null) => {
    switch (fieldName) {
      case 'currentFrequency':
        return;
      case 'newFrequency':
        if (value !== null && typeof value === 'string') {
          const regex = /^(?:\d{1,3}(?:\.\d*)?|\.\d+)$/;
          if (value.trim() === '') {
            setNewFrequencyErrorMessage('Frequency must be a positive number');
          } else if (!regex.test(value)) {
            setNewFrequencyErrorMessage('Frequency must be a positive number');
          } else {
            setNewFrequencyErrorMessage('');
          }
          setupdatedNewFrequency(value);
        }
        break;
      case 'description':
        setupdatedScheduledDescription(value as string);
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    onClose();
  };

  const validateTimeFormat = (time: string): boolean => {
    const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    if (!regex.test(time)) return false;
    const [hours, minutes] = time.split(':').map(Number);
    return hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59;
  };

  const handleTimeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    value = value.replace(/[^0-9:]/g, '');
    if (value.length > 5) {
      value = value.slice(0, 5);
    }
    if (value.length > 2 && value[2] !== ':') {
      value = `${value.slice(0, 2)}:${value.slice(2)}`;
    }
    if (value.length > 5 && !validateTimeFormat(value)) {
      toast.error('Time must be in HH:MM format with exactly 2 digits for hours and minutes');
    } else {
      const splitedArray = value?.split(':')
      if (splitedArray?.length === 1 && Number(splitedArray[0]) <= 12) {
        setTimeInput(value)
      } else if (splitedArray?.length === 2 && Number(splitedArray[1]) < 60) {
        setTimeInput(value)
      }
    }
  };
  const handleAmPmChange = (value: string) => {
    setAmPmInput(value);
  };

  const handleDateSelection = (date: DateObject | DateObject[] | null) => {
    if (date) {
      const selectedDate = Array.isArray(date) ? date[0].toDate() : date.toDate();
      setupdatedScheduledDate(selectedDate);
    } else {
      setupdatedScheduledDate(null);
    }
  };

  return isOpen ? (
    <>
      <ToastContainer />
      <div className='scheduleSpeed__add-block'>
        {showHeader && (
          <div className='scheduleSpeed__header-bar'>
            <button onClick={handleBack}>
              <img src={Back_Left} alt='close' />
              <span>Back to scheduled speed change</span>
            </button>
          </div>
        )}

        <div className='scheduleSpeed__profile-block'>
          <div className='scheduleSpeed__notify-card'>
            <div className='scheduleSpeed__header'>
              <span className='title'>Create new scheduled speed change</span>
              <div>{wellName}</div>
            </div>
            <div className='scheduleSpeed__form-body'>
              <div className='time-block'>
                <div className='scheduleSpeed__form-control relative'>
                  <label className='label'>Current frequency</label>
                  <input
                    type='text'
                    className='time-input-field'
                    placeholder={'57.4 Hz'}
                    // value={currentFrequency || ''}

                    disabled={true}
                  />
                  <img src={Question} alt='question-mark' className='absolute right-9 top-4' />
                </div>
                <div className='scheduleSpeed__form-control'>
                  <label className='label'>New frequency</label>
                  <div className={`input-field__container ${newFrequencyErrorMessage ? 'error-container' : ''}`}>
                    <input
                      type='text'
                      className='time-input-field'
                      placeholder='Enter new frequency'
                      value={updatedNewFrequency}
                      onChange={(e) => {
                        const pattern = /^\d{0,3}(\.\d{0,1})?$/
                        const value = e.target.value
                        if (pattern.test(value)) {
                          handleInputChange('newFrequency', e.target.value)
                        } else {
                          e.preventDefault()
                        }
                      }
                      }
                    />
                    {newFrequencyErrorMessage && <img src={Info_Circle} alt='Info_Circle' className='error-icon' />}
                    {newFrequencyErrorMessage && <p className='error-message'>{newFrequencyErrorMessage}</p>}
                  </div>
                </div>
                <div className='scheduleSpeed__form-control'>
                  <label className='label'>Schedule time</label>
                  <div className='date-time-picker'>

                    <DatePicker
                      mobileLabels={{ OK: 'Apply', CANCEL: 'Cancel' }}
                      format='MM/DD/YYYY'
                      className='date-input-field rmdp-mobile bg-dark'
                      placeholder='MM/DD/YYYY'
                      weekDays={["Mo", "Tu", "We", "Th", "Fr", "Sat", "Su"]}
                      value={updatedScheduledDate ? new Date(updatedScheduledDate) : new Date()}
                      onChange={(selectedDate) => {
                        handleDateSelection(selectedDate);
                      }}
                      plugins={[<Toolbar position='top' sort={['today']} />]}
                      minDate={new Date().setHours(0, 0, 0, 0)}
                      showOtherDays={true}
                    />
                    <img src={Calendar} alt='calendar' />
                    <input
                      type='text'
                      className='time-input-field hours-mints'
                      placeholder='HH : MM'
                      value={timeInput}
                      maxLength={5}
                      onChange={handleTimeInputChange}
                    />
                    <div className='ampm-button-group'>
                      <button
                        type='button'
                        className={`ampm-button ${ampmInput === 'AM' ? 'active' : ''}`}
                        onClick={() => handleAmPmChange('AM')}
                      >
                        AM
                      </button>
                      <span className='scheduleSpeed__seperator'></span>
                      <button
                        type='button'
                        className={`ampm-button ${ampmInput === 'PM' ? 'active' : ''}`}
                        onClick={() => handleAmPmChange('PM')}
                      >
                        PM
                      </button>
                    </div>
                  </div>
                </div>
                <div className='scheduleSpeed__form-control'>
                  <label className='label'>Description</label>
                  <textarea
                    className='time-input-field description-input-field'
                    placeholder='Enter description about scheduled speed change'
                    value={updatedScheduledDescription}
                    onChange={(e) => handleInputChange('description', e.target.value)}
                  />
                </div>
              </div>
              <div className='divider'></div>
              <div className='scheduleSpeed__btn-notify'>
                <button type='button' className='btn btn-secondary' onClick={onClose}>
                  Cancel
                </button>
                {!editMode && (
                  <button type='button' className='btn btn-primary' onClick={handleCreateOrUpdate}>
                    Create
                  </button>
                )}
                {editMode && (
                  <button type='button' className='btn btn-primary' onClick={handleCreateOrUpdate}>
                    Update
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default ScheduledSimpleSpeedChange;
