import { useAppSelector } from '../../../../../hooks/storeHooks';
import { AssetDetailsProps } from '../AssetDetailProps';
import '../details-table.scss';
import moment from 'moment-timezone';


interface AttributeProps {
  assetDetails?: AssetDetailsProps;
}

const ControlStatus: React.FC<AttributeProps> = ({ assetDetails }) => {
  const assetControlData = useAppSelector((state) => state?.assetControl)

  const convertMinutesToDHMS = (minutes: number) => {

    if (minutes < 60) {
      return `${minutes}m`;
    }

    const days = Math.floor(minutes / (60 * 24));
    const hours = Math.floor((minutes % (60 * 24)) / 60);
    const remainingMinutes = minutes % 60;

    let result = '';

    if (days > 0) {
      result += `${days}d `;
    }

    if (hours > 0) {
      result += `${hours}h `;
    }

    if (remainingMinutes > 0) {
      result += `${remainingMinutes}m`;
    }

    return result;
  }
  return (
    <>
      <div className='details-header-section'>
        <div className='title'>
          <h1>Controls & status</h1>
        </div>
        <div className='details-section'>
          <div className='details-table-row'>
            <div className='details-table-column'>
              <div>Current run status</div>
              <div>{assetDetails?.currentRunStatus}</div>
            </div>
            <hr />

            <div className='details-table-column'>
              <div>Time in status</div>
              <div>{convertMinutesToDHMS(Number(assetDetails?.timeInStatus))}</div>
            </div>
            <hr />

            <div className='details-table-column'>
              <div>Restart delay remaining</div>
              <div>{assetDetails?.restartDelayRemaining ?? '-'}</div>
            </div>
            <hr />

            <div className='details-table-column'>
              <div>Operating mode</div>
              <div>{assetControlData?.operatingMode ?? '-'}</div>
            </div>
            <hr />

            <div className='details-table-column'>
              <div>Last scan</div>
              <div>{assetDetails?.lastScan ? moment(assetDetails?.lastScan).format('MM/DD/YYYY hh:mm:ss A') : '-'}</div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </>
  );
};
export default ControlStatus;
