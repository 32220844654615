//Security.tsx
import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import './Security.scss';
import { updateUserPermissions, fetchUserById } from '../../UserSlice';
import { Role } from '../../../../models/Role';
import { AppUser } from '../../model/AppUser';
import Loader from '../../../common/page-loader/ComponentLoader';

interface TabsProps {
  value?: string | undefined;
}
const Security: React.FC<TabsProps> = ({ value }) => {
  const dispatch = useAppDispatch();
  const storedUserData = localStorage.getItem('loggedInUser');
  const loading = useAppSelector((state) => state?.user?.loading);
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  let currentUser = useAppSelector((state) => state?.user?.currentUser);
  currentUser = currentUser || storedUser
  const [adminClass, setAdminClass] = useState('btn-transparent');
  const [adminLiteClass, setAdminLiteClass] = useState('btn-transparent');
  const [wellAdminClass, setWellAdminClass] = useState('btn-transparent');
  const [wellControlClass, setWellControlClass] = useState('btn-transparent');
  const [wellConfigClass, setWellConfigClass] = useState('btn-transparent');
  const [currentRoles, setCurrentRoles] = useState<Role[]>(currentUser?.roles || []);
  const userHighestRole = currentRoles.length > 0 ? Math.min(...currentRoles) : Infinity;


  useEffect(() => {
    const fetchUser = async () => {
      if (value) {
        await dispatch(fetchUserById(value));
      }
    };

    fetchUser();
  }, [dispatch, value]);

  useEffect(() => {
    // Check if currentUser exists and has roles
    if (currentUser && currentUser.roles) {
      // Check for each role and update the corresponding class
      setAdminClass(currentUser.roles.includes(1) ? 'btn-orange' : 'btn-transparent');
      setAdminLiteClass(currentUser.roles.includes(2) ? 'btn-orange' : 'btn-transparent');
      setWellAdminClass(currentUser.roles.includes(3) ? 'btn-orange' : 'btn-transparent');
      setWellControlClass(currentUser.roles.includes(4) ? 'btn-orange' : 'btn-transparent');
      setWellConfigClass(currentUser.roles.includes(5) ? 'btn-orange' : 'btn-transparent');
    }
  }, [currentUser]);

  useEffect(() => {
    updateButtonClasses();
  }, [currentRoles, userHighestRole]);

  const updateButtonClasses = () => {
    event?.preventDefault();
    setAdminClass(currentRoles.includes(1) ? 'btn-orange' : 'btn-transparent');
    setAdminLiteClass(currentRoles.includes(2) ? 'btn-orange' : 'btn-transparent');
    setWellAdminClass(currentRoles.includes(3) ? 'btn-orange' : 'btn-transparent');
    setWellControlClass(currentRoles.includes(4) ? 'btn-orange' : 'btn-transparent');
    setWellConfigClass(currentRoles.includes(5) ? 'btn-orange' : 'btn-transparent');

  };

  const handleRoleClick = (roleNumber: Role) => {
    setCurrentRoles((prevRoles) => {
      const updatedRoles = prevRoles.includes(roleNumber)
        ? prevRoles.filter((role) => role !== roleNumber)
        : [...prevRoles, roleNumber];

      if (currentUser?.id) {
        // Dispatch the action to update the user's roles
        dispatch(
          updateUserPermissions({
            id: currentUser.id,
            roles: updatedRoles,
          }),
        ).then(() => {
          // Update roles in localStorage
          dispatch(fetchUserById(storedUser.id))
          localStorage.setItem('loggedInUser', JSON.stringify({ ...currentUser, roles: updatedRoles }));
        })
      }

      return updatedRoles;
    });
  };
  // // useEffect(() => {
  // //   const storedUserData = localStorage.getItem('loggedInUser');
  // //   const storedUser: AppUser | null = storedUserData ? JSON.parse(storedUserData) : null;
  // //   if (storedUser && storedUser.roles) {
  // //     setCurrentRoles(storedUser.roles);
  // //     // Initialize button classes based on stored user roles
  // //     setButtonClasses(storedUser.roles);
  // //   }
  // // }, []);
  // console.log("currentroles", currentRoles)
  // // Function to set button classes based on roles
  // const setButtonClasses = (roles: Role[]) => {
  //   setAdminClass(roles.includes(1) ? 'btn-orange' : 'btn-transparent');
  //   setAdminLiteClass(currentRoles.includes(2) ? 'btn-orange' : 'btn-transparent');
  //   setWellAdminClass(currentRoles.includes(3) ? 'btn-orange' : 'btn-transparent');
  //   setWellControlClass(currentRoles.includes(4) ? 'btn-orange' : 'btn-transparent');
  //   setWellConfigClass(currentRoles.includes(5) ? 'btn-orange' : 'btn-transparent');
  // };

  return (
    <>
      {loading ? (
        <div className='flex items-center loader-margin'>
          <Loader />
        </div>
      ) : currentUser ? (
        <div className='flex flex-row flex-grow profile-block w-100 py-6 px-8'>

          <div className='card security-card m-0'>
            <div className='header'>
              <p className='title'>Security settings</p>
              <p className='normal-text'>View your XSPOC account permission here.</p>
            </div>
            <div className='body'>
              <div className='security-info'>
                <div className='protext'>
                  <div className='text'>User name</div>
                </div>
                <div className='card'>
                  <form className='user-form'>
                    <div className='form-row'>
                      <div className='form-col full-col'>
                        <input
                          type='text'
                          id='userName'
                          className='username-custom-input'
                          value={currentUser?.userID}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <br />
              <div className='divider'></div>
              <br />
              <br />
              <div className='profile-info'>
                <div className='protext'>
                  <div className='text'>User security permissions</div>
                  <p>View your XSPOC account permission here,</p>
                </div>
                <div className='card'>
                  <form className='password-form'>
                    <div className='form-row'>
                      <button
                        className={`btn ${adminClass}`}
                        onClick={() => handleRoleClick(Role.Admin)}
                        disabled={userHighestRole !== null && Role.Admin < userHighestRole}
                      >
                        Admin
                      </button>

                      <button
                        className={`btn ${adminLiteClass}`}
                        onClick={() => handleRoleClick(Role.Adminlite)}
                        disabled={userHighestRole !== null && Role.Adminlite < userHighestRole}
                      >
                        Admin lite
                      </button>

                      <button
                        className={`btn ${wellAdminClass}`}
                        onClick={() => handleRoleClick(Role.Welladmin)}
                        disabled={userHighestRole !== null && Role.Welladmin < userHighestRole}
                      >
                        Well admin
                      </button>

                      <button
                        className={`btn ${wellControlClass}`}
                        onClick={() => handleRoleClick(Role.Wellcontrol)}
                        disabled={userHighestRole !== null && Role.Wellcontrol < userHighestRole}
                      >
                        Well control
                      </button>

                      <button
                        className={`btn ${wellConfigClass}`}
                        onClick={() => handleRoleClick(Role.Wellconfig)}
                        disabled={userHighestRole !== null && Role.Wellconfig < userHighestRole}
                      >
                        Well config
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>
      ) : ''
      }
    </>
  );
};

export default Security;
