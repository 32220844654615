import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.scss';
import XPSOCImage from '../../../images/Logomark.svg';
import AssetListImage from '../../../images/asset-list.svg';
import NotificationImage from '../../../images/notification.svg';
import LogoutImage from '../../../images/logout.svg';
import NotificationBottomIcon from '../../../images/notification-bottom.svg';
import GearIcon from '../../../images/settings-icon.svg';
import admin from '../../../images/admin.svg';
import Tooltip from '../../common/tooltip/ToolTip';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import NavigationDrawerItem from './NavigationDrawerItem';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { setDrawerState } from './NavigationSlice';
import HomeIcon from '../../../images/homeIcon.svg';
import { AppUser } from '../../user/model/AppUser';
// import { logoutSuccess } from '../../user/UserSlice';
import { getWebSocketURI } from '../../../utilities/BaseURLUtility';
import { updateSocketID } from '../AssetGroupsSlice'
import { setExecuteControlActionOnReturnError, setExecuteControlActionOnReturnSuccessful, updateAssetControlScanStatus, updateAssetStatus } from '../../asset/AssetControlSlice';
import { handleReadDeviceReturnError, handleReadDeviceReturnSuccess } from '../../asset-details/components/setpoints/SetPointsSlice';
import { fetchUserById } from '../../user/UserSlice';

export enum Action {
  Unknown = 0,
  ProcessDeviceScan = 1,
  StartWell = 2,
  StopWell = 3,
  IdleWell = 4,
  ClearAlarms = 5,
  CommunicationLog = 18,
  ConstantRunMode = 6,
  PocMode = 7,
  PercentTimerMode = 8,
  SetClock = 9,
  UpdatePocData = 10,
  GetCard = 11,
  DownloadEquipment = 12,
  UploadEquipment = 13,
  EnablePid = 14,
  DisablePid = 15,
  GetData = 16,
  GetCardDirect = 17,
  CaptureRegisterLogs = 19,
  OnOffCycles = 20,
  StartPortLogging = 21,
  StopPortLogging = 22,
}
const NavigationBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const [expandDrawer, setExpandDrawer] = useState(false);
  const [selectedTooltip, setSelectedTooltip] = useState<string | null>('home'); // Set 'home' as default
  let currentUser = useAppSelector((state) => state.user.currentUser);
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser | null = storedUserData ? JSON.parse(storedUserData) : null;
  const groupName = useAppSelector((state) => state?.assetGroups?.selectedAssetName)
  const setPointData = useAppSelector((state) => state.setPoint)

  if (currentUser?.id != storedUser?.id)
    currentUser = storedUser

  useEffect(() => {
    const fetchUser = async () => {
      if (storedUser) {
        await dispatch(fetchUserById(storedUser?.id));
      }
    };
    fetchUser();
  }, []);
  useEffect(() => {
    const webSocketURI = getWebSocketURI('wellcontrolws');
    const socket = new WebSocket(webSocketURI);
    // Connection opened
    socket.addEventListener('open', (event) => {
      console.log("Connection established")
      socket.send('Connection established' + event);
    });
    // Listen for messages
    socket.addEventListener('message', (event) => {
      if (event.data.startsWith('socketId:')) {
        dispatch(
          updateSocketID(event.data.split(':')[1]),
        );
      } else if (event.data === 'Close') {
        socket.close();
      } else {
        const parsedData = JSON.parse(event.data);
        console.log("parsedData", parsedData)
        if (parsedData.Status == 'Success') {
          const payloadData = JSON.parse(parsedData.Payload);
          if (payloadData && payloadData?.Value) {
            const payloadDetails = JSON.parse(payloadData?.Value?.Payload);
            const nodeId = payloadDetails?.NodeId || payloadDetails?.DeviceScanData?.NodeId;
            dispatch(
              setExecuteControlActionOnReturnSuccessful({
                message: getUpdatedToastMessage(Number(payloadData.Value?.ActionType), nodeId),
                data: parsedData.Payload,
              }),
            );
            if (Number(payloadData.Value?.ActionType) === Action.ProcessDeviceScan) {
              dispatch(updateAssetControlScanStatus(true))
            } else if (Number(payloadData.Value?.ActionType) === Action.StartWell) {
              dispatch(updateAssetStatus('Running'))
            } else if (Number(payloadData.Value?.ActionType) === Action.StopWell) {
              dispatch(updateAssetStatus('Shutdown'))
            }
          } else {
            const payload = JSON.parse(parsedData.Payload)
            if (payload) {
              const addressArray: number[] = payload["1"];
              const valuesArray: number[] = payload["3"];
              let newArray: { address: number, value: number }[] = []
              // Check if both arrays have the same length
              if (addressArray.length === valuesArray.length) {
                // Create a new array with key-value pairs
                newArray = addressArray.map((key, index) => ({
                  address: key,
                  value: valuesArray[index]
                }));
              }
              dispatch(handleReadDeviceReturnSuccess(newArray))
            }
          }
        } else {
          if (parsedData.StatusMessage) {
            if (setPointData?.readDeviceLoading) {
              dispatch(handleReadDeviceReturnError())
            } else {
              dispatch(
                setExecuteControlActionOnReturnError({
                  message: groupName ? `Transaction failed for ${groupName}` : 'Transaction failed',
                }),
              )
            }
          }
        }
      }
    });

    // Clean up WebSocket connection when component unmounts
    return () => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.close();
      }
    };
  }, []);

  const getUpdatedToastMessage = (controlActionType: number, wellName = 'well') => {
    let returnMessage = '';
    switch (controlActionType) {
      case Action.StartWell:
        returnMessage = `${wellName} successfully started`;
        break;
      case Action.StopWell:
        returnMessage = `${wellName} successfully shutdown`;
        break;
      case Action.ClearAlarms:
        returnMessage = `Alarms successfully cleared for ${wellName}`;
        break;
      case Action.ProcessDeviceScan:
        returnMessage = `Scan is completed for ${wellName}`;
        break;

      default:
        returnMessage = `Transaction updated successfully for ${wellName ?? 'well'}`;
    }
    return returnMessage;
  };

  const handleLogout = () => {
    localStorage.clear();
    const logoutUrl = process.env.REACT_APP_LOGOUT_URL || '';
    window.location.href = logoutUrl;
    // dispatch(logoutSuccess());
    //navigate('/login');
  };

  const handleAssetListClick = () => {
    const shouldDisplayDrawer = !expandDrawer;
    dispatch(setDrawerState(shouldDisplayDrawer));
    setExpandDrawer(shouldDisplayDrawer);
  };

  const handleTooltipClick = (tooltipName: string) => {
    window.scrollTo(0, 0);
    setSelectedTooltip(tooltipName);
  };

  const handleSlideOutClick = (e: any) => {
    if (e.target.id === 'asset-list-image') return;
    if (expandDrawer) {
      dispatch(setDrawerState(!expandDrawer));
      setExpandDrawer(!expandDrawer);
    }
  };

  const ref = useDetectClickOutside({ onTriggered: handleSlideOutClick });

  return (
    <>
      <div className='navigation-bar-container'>
        <div className='navigation-bar-top-container'>
          <img id='xspoc-logo-image' src={XPSOCImage} alt='XSPOC Image' className='navigation-bar-logo-image' />

          <Tooltip content='Landing page' direction='right'>
            <Link to='/'>
              <div
                data-testid='asset-dashboard-image'
                className={`navigation-bar-navigation-image-container ${selectedTooltip === 'home' ? 'selected' : ''}`}
                onClick={() => {
                  setSelectedTooltip('home');
                }}
              >
                <img id='asset-dashboard-image' src={HomeIcon} alt='Asset Dashboard Image' />
              </div>
            </Link>
          </Tooltip>

          <Tooltip content='Groups and assets' direction='right'>
            <div
              data-testid='group-and-assets-image'
              className={`navigation-bar-navigation-image-container ${selectedTooltip === 'groupsAndAssets' ? 'selected' : ''
                }`}
              onClick={() => {
                handleAssetListClick();
                handleTooltipClick('groupsAndAssets');
              }}
            >
              <img id='asset-list-image' src={AssetListImage} alt='Asset List Image' />
            </div>
          </Tooltip>

          <div className='navigation-bar-navigation-section-container' style={{ display: 'none' }}>
            <Tooltip content='Notification' direction='right'>
              <Link to='/layout/notification'>
                <div
                  className={`navigation-bar-navigation-image-container ${selectedTooltip === 'notification' ? 'selected' : ''
                    }`}
                  onClick={() => handleTooltipClick('notification')}
                >
                  <img id='notification-image' src={NotificationImage} alt='Notification Image' />
                </div>
              </Link>
            </Tooltip>
          </div>

          <div className='navigation-bar-slide-out-section-container'>
            <Tooltip content='Notifications' direction='right'>
              <div
                className={`navigation-bar-navigation-image-container ${selectedTooltip === 'notifications' ? 'selected' : ''
                  }`}
                onClick={() => handleTooltipClick('notifications')}
                style={{ display: 'none' }}
              >
                <img src={NotificationBottomIcon} alt='Notifications' />
              </div>
            </Tooltip>

            {currentUser?.roles?.includes(1) && (
              <Tooltip content='Admin tools' direction='right'>
                <Link to='/layout/admin'>
                  <div
                    className={`navigation-bar-navigation-image-container ${selectedTooltip === 'adminProfiles' ? 'selected' : ''
                      }`}
                    onClick={() => handleTooltipClick('adminProfiles')}
                  >
                    <img src={admin} alt='Admin Profiles' />
                  </div>
                </Link>
              </Tooltip>
            )}
            <Tooltip content='Settings' direction='right'>
              <Link to='/layout/user'>
                <div
                  className={`navigation-bar-navigation-image-container ${selectedTooltip === 'userSettings' ? 'selected' : ''
                    }`}
                  onClick={() => handleTooltipClick('userSettings')}
                >
                  <img src={GearIcon} alt='Settings' />
                </div>
              </Link>
            </Tooltip>

            <Tooltip content='Log out' direction='right'>
              <Link to='/login' onClick={handleLogout}>
                <div
                  className={`navigation-bar-navigation-image-container ${selectedTooltip === 'logout' ? 'selected' : ''
                    }`}
                  onClick={() => handleTooltipClick('logout')}
                >
                  <img id='logout-image' src={LogoutImage} alt='Logout Image' />
                </div>
              </Link>
            </Tooltip>
          </div>
        </div>
        <div ref={ref}>{expandDrawer && <NavigationDrawerItem />}</div>
      </div>
    </>
  );
};

export default NavigationBar;
