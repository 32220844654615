import React, { useEffect, useState } from 'react';
import SetPointList from './components/SetPointList';
import SetPointDetails from './components/SetPointDetails';
import './SetPoints.scss';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { fetchDeviceValue, fetchSetPoint, resetReadDeviceStatus, updateSetpointValues } from './SetPointsSlice';
import Loader from '../../../common/page-loader/ComponentLoader';
import chevronDown from '../../../../images/chevron-down.svg';
import { updateAssetControlScanStatus } from '../../../asset/AssetControlSlice';
import SetPointToast from '../../../common/toast/SetPointToast';
import { AppUser } from '../../../user/model/AppUser';

interface TabsProps {
  value?: string | undefined;
}

const SetPoints: React.FC<TabsProps> = ({ value }) => {
  const selectedAsset = useAppSelector((state) => state.assetGroups);
  const assetControlData = useAppSelector((state) => state?.assetControl)
  const wellId = selectedAsset.selectedAssetId ?? '';
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.setPoint.loading);
  const [selectedSetpointGroup, setSelectedSetpointGroup] = useState<number | null>(null);
  const [selectedSetPointsAddress, setSelectedSetPointsAddress] = useState<string | undefined>()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const socketID = useAppSelector((state) => state?.assetGroups?.socketID)
  const setPointData = useAppSelector((state) => state.setPoint)


  const loggedInUser = useAppSelector((state) => state?.user?.loggedInUser);
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser | null = storedUserData ? JSON.parse(storedUserData) : null;
  const initialUserState = loggedInUser || storedUser || undefined;
  useEffect(() => {
    if (wellId) {
      dispatch(fetchSetPoint(wellId));
      console.log(value)
      resetSetPointStatus()
    }
    if (assetControlData?.assetControlScanStatus) {
      dispatch(updateAssetControlScanStatus(false))
    }
  }, [wellId, assetControlData?.assetControlScanStatus === true]);

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (event.target instanceof HTMLElement && event.target.closest('.synchronize-dropdown')) return;
      setIsDropdownOpen(false);
    };
    document.body.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.body.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);


  const handleSetPointGroupSelect = (setpointGroup: number, selectedAddress: string | undefined) => {
    dispatch(resetReadDeviceStatus('All'))
    setSelectedSetPointsAddress(selectedAddress)
    setSelectedSetpointGroup(setpointGroup);
  };
  const clickDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const readDevice = () => {
    if (selectedSetPointsAddress) {
      dispatch(fetchDeviceValue({ wellId: wellId, setPointAddresses: selectedSetPointsAddress, socketId: socketID }))
    }
  }

  const updateDevice = () => {
    if (setPointData?.changedBackupValues) {
      dispatch(updateSetpointValues({ wellId: wellId, setpointValues: setPointData?.changedBackupValues, socketId: socketID }));
    }
  }
  const resetSetPointStatus = () => {
    dispatch(resetReadDeviceStatus('All'))
    setSelectedSetPointsAddress(undefined)
    setSelectedSetpointGroup(null);
  }
  return (
    <>
      <div className='profile-container w-100 py-6 px-8 ml-0'>

        {
          (setPointData?.readDeviceLoading || setPointData?.readDeviceSuccessStatus || setPointData?.readDeviceErrorStatus || setPointData?.readDeviceReturnErrorStatus) &&
          <SetPointToast isShowEnabled={setPointData?.readDeviceLoading || setPointData?.readDeviceSuccessStatus || setPointData?.readDeviceErrorStatus || setPointData?.readDeviceReturnErrorStatus} />
        }
        {loading ? (
          <div className='set-point-loader flex items-center justify-center w-100'>
            <Loader />
          </div>) : (
          <div className='profile-status-header'>
            <div className='tab-content set-point-wrap mt-0'>
              <div className='card profile-card m-0'>
                <div className='header'>
                  <span className='title'>Setpoints </span>
                  <div>Select the group of registers that you would like to modify</div>
                </div>
                <div className='body user-details-body'>
                  <div className='btn-wrapper'>
                    <button className={`setpoint-btn ${selectedSetpointGroup && 'selected'}`} onClick={readDevice}>Read Device</button>
                    <button className={`setpoint-btn ${setPointData?.deviceReadStatus && 'selected'}`}>Backup Setpoints</button>
                    <button className={`setpoint-btn ${setPointData?.deviceReadStatus && 'selected'}`} onClick={() => setPointData?.deviceReadStatus && updateDevice()}>Update Device</button>
                    <button className={`setpoint-btn synchronize-dropdown ${initialUserState?.roles.includes(4) ? 'enabled' : ''}`} onClick={() => { initialUserState?.roles.includes(4) && clickDropdown() }}>
                      Synchronize All <img src={chevronDown} alt='chevron' className={`${isDropdownOpen ? 'rotate-180' : ''}`} />

                      {isDropdownOpen && (
                        <div className='dropdown-options synchronize-option'>
                          <button>Make a backup copy</button>
                          <button>Send backup copy to device</button>
                        </div>
                      )}
                    </button>
                  </div>

                  <div className='adminuser-block'>
                    <div className='flex flex-row'>
                      <div className='basis-1/5 first-half'>
                        <SetPointList onSelectSetPointGroup={handleSetPointGroupSelect} userRoles={initialUserState?.roles} />
                      </div>
                      <div className='basis-4/5'>
                        <div className='setpoint-userblock p-6'>
                          <SetPointDetails selectedSetpointGroup={selectedSetpointGroup} wellId={wellId as string} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        }
      </div>
    </>
  );
};

export default SetPoints;
