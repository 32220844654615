import React, { useState } from 'react';
import { useAppSelector } from '../../../../../hooks/storeHooks';
import Setpointoptimization from '../../../../../images/setpoint-optimization.svg';
import '../SetPoints.scss';

interface SetPointListProps {
  onSelectSetPointGroup: (setpointGroup: number, selectedAdress: string | undefined) => void;
  userRoles: number[] | undefined
}
const SetPointList: React.FC<SetPointListProps> = ({ onSelectSetPointGroup, userRoles }) => {
  const setPoints = useAppSelector((state) => state?.setPoint?.apiResponse?.values);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleSetPointClick = async (index: number, setpointGroup: number) => {
    const array: string[] = setPoints
      ?.filter((setPoint) => setPoint?.setpointGroup === setpointGroup)
      ?.flatMap((setPoint) => setPoint?.setpoints?.map((point: any) => point?.parameter) || []) || [];
    if (userRoles?.includes(4)) {
      setSelectedIndex(index);
      onSelectSetPointGroup(setpointGroup, array?.join(','));

    }
  };

  return (
    <div className='user-checklist setpoint-block'>
      <ul className='setpoint-check-list'>
        {setPoints?.length ? (
          setPoints.map((setPoint, index) => (
            <li
              className={`setpoint-list-item ${index === selectedIndex ? 'selected' : ''}`}
              key={index}
              onClick={() => handleSetPointClick(index, setPoint.setpointGroup)}
            >
              <div className='info setpoint-info'>
                <div>
                  <img src={Setpointoptimization} alt='' />
                </div>
                <div>
                  <div className='namedata'>{setPoint.setpointGroupName}</div>
                  <div className='emaildata'>{setPoint.registerCount} registers</div>
                </div>
              </div>
            </li>
          ))
        ) : (
          <li>
            <div className='notfound'>No set point found.</div>
          </li>
        )}
      </ul>
    </div>
  );
};

export default SetPointList;
